<template>
  <div class="card card-animation-on-hover text-left" style="margin: 4px;padding: 4px;"
  >
    <a :href="this.event.metadata.link">
      <img
        v-lazy=" this.event.metadata.main_image.imgix_url + `?q=&w=${imageSize}&h=${imageSize}&fit=clamp`"
        style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 0"
        v-bind:alt="this.event.title"
        :height="imageSize"
        :width="imageSize"
      />
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; line-height: 0.7rem"
        v-if="!this.past">
        <span style="font-weight: 700 !important;" v-if="!withoutBrand">{{ this.event.title.split("@")[0] }}</span>
        <span style="font-weight: 700 !important;" v-if="withoutBrand">{{ this.event.title.split("@")[0].split('|')[0] }}</span>
        <p>
          {{ getDay(this.event.metadata.date + 'T' + this.event.metadata.start_time) }},
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }} ●

          {{
            event.metadata.start_time
          }} Hrs
        </p>
        <small v-if="this.withVenue" style="margin-top: -50px;color: silver;text-transform: uppercase">{{ venue }} </small>
        <small style="color: silver;text-transform: uppercase" v-if="this.withBrand" >  {{ brand }} </small>
      </div>
      <small style="font-size: 0.6rem;background-color: #0e0e0e" class="pull-right">
        <p>{{this.event.title.split("@")[1]}}</p>
       </small>
    </a>
  </div>
</template>
<script>
import moment from 'moment';
import {venueDecoder, brandDecoder} from "@/common/Decoder";

export default {
  name: 'MiniCaboEventCard',
  props: ['event', 'past', 'withVenue', 'withoutBrand', 'large', 'withBrand'],
  data: () => {
    return {};
  },
  computed: {
    imageSize: {
      get: function () {
        return  this.large ? '130' : '100';
      },
    },
    venue: {
      get: function () {
        return venueDecoder(this.event.title)?.title || 'tulum';
      },
    },
    brand: {
      get: function () {
        return brandDecoder(this.event.title)?.title || '';
      },
    }
  },
  methods: {
    getDay: function getYear(date) {
      return moment(date, true).format('ddd')
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD')
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM')
    },
    getYear: function getMonth(date) {
      return moment(date, true).format('YYYY')
    },

  }
};
</script>

