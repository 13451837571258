<template>
  <div class="content ">
    <div class="text-center  ">
      <div class="header text-center" style="margin-bottom: 10px;">
        <h1 class="tulum-party-title " style="letter-spacing: 0.5rem;">
          CABO SAN LUCAS
        </h1>
        <h3 style="font-size: 0.9rem;margin-top: -25px">Unleash the Magic of Los Cabos nightlife
        </h3>
        <img
          v-lazy="caboImg+'?w=500&h=200&fit=clamp'"
          width="500"
          height="200"
          alt="san jose cabo events"
        >
        <hr>

        <p
          style="font-size: 14px;color: #3ea2c1 !important;letter-spacing: 0.2rem;;padding: 1px 60px 2px 60px">
          A hidden place between the ocean and the dessert, a surreal location with the best of the views & music. A new
          story is about to start.
        </p>
        <hr>
        <p style="font-size: 16px"><strong>Located at the southern tip of the Baja California Peninsula, Los
          Cabos </strong> offers some of the most breathtaking views of the Pacific Ocean and the Sea of Cortez.
          Cabo San Lucas is where you want to be if you are looking for a coastal paradise with lively <a
            href="https://www.cabo.party/los-cabos-clubs">nightlife</a> and
          <strong>endless options for fun!</strong></p>
        <br>

      </div>


      <img v-lazy="bgPalm" v-if="showPalms"
           class="d-inline-sm d-md-none"
           alt="tulum.party crazy palm"
           style="position:fixed;left: 5%;bottom: 5%;opacity: 0.1;width: 100%;z-index: 0">
      <card class="card text-center" style="border: 1px solid black;background-color:#1a1818">
        <template slot="header">

          <div style="z-index: 1;" class="card">

            <div style="
">

              <a href="https://www.cabo.party/los-cabos-mexico-event-calendar">
                <img
                  v-lazy="djImage +
          '?w=290&h=180&fit=clamp&auto=mode'
        "
                  alt="Cabo Club Bookings"
                  width="290px"
                  height="180px"
                  class="card-animation-on-hover"
                  style="
          margin-top: -10px;
          opacity: 0.8;
          z-index: 12;
          box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px;
        "
                />
              </a>

              <br>
              <br>
              <p>Check out the <a href="https://www.cabo.party/los-cabos-mexico-event-calendar">los cabo event
                calendar</a> for the full dinner/show & event list</p>
              <br>

              <a
                :href="`https://wa.me/${this.contactNumber}?text=CABO_INFO`"
                v-on:click="onQuickReserveClick()"
                class="btn font-weight-bold"
                style="
                background-color: #00f2c3 !important;
                color:black !important;
                border: 1px solid silver;
                z-index: 2;
              "
              >
                📲 INFO, RSVP & SUPPORT
              </a>
              <br/>
              <br/>
              <p>Want to talk with an expert?</p>
              <small> We operate from 8AM - 11PM / Answer within 15 mins</small>
              <br/>
              <small style="color: #fff">NO EXTRA FEE</small>
            </div>
          </div>
          <br>


          <br>
          <br>
        </template>
      </card>
      <div class="text-center " style="padding: 10px;background-color: #1a1818;border: 1px solid black"
           v-if="caboParties.length"
      >

        <h4
          style="
            background-color: black;
            margin-top: 1px;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: -10px;
            text-transform: uppercase;
          "
          class="tulum-party-subtitle;"
        >
          Event Line
        </h4>
        <br/>
        <div>
          <div
            v-for="(event, idx) in caboParties"
            :key="event._id"
            :inverted="idx % 2 === 1"
            :badge-type="badgeType(idx)"
          >
            <div slot="content">
              <MiniCaboEventCard
                v-bind:event="event"
                :key="event._id"
                :with-lineup="false"
                :idx="idx"
              ></MiniCaboEventCard>
            </div>
          </div>
        </div>
        <br/>
      </div>

      <div class="row">

        <div class="col-12">
          <h3 class="tulum-party-title" style="background-color: #3dbda2">Cabo Top Clubs</h3>

        </div>

        <div class="col-sm-12 col-md-6">


          <ImgCard title="Taboo Los Cabo"
                   link="https://www.cabo.party/los-cabos-clubs/taboo-los-cabos"
                   action-label="DETAILS"
                   sub-title="Taboo Los Cabos is located inside the five-star hotel ME Cabo at Medano Beach."
                   image="https://imgix.cosmicjs.com/bc3f0f60-f62b-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos.jpg"
                   text="<p>You will find their characteristic <b>sax & violin shows</b> as well as their champagne happenings with <b>sparklers, some fire shows, pool parties & day activities</b>, and even some <b>special events with high-quality artists</b> such as <strong>Hugel, Solardo, </strong> and <a href='https://www.cabo.party/event/claptone-taboo-los-cabos-cabo-san-lucas'>Claptone</a> to name a few.The food is mostly Mediterranean and they have a wide variety of cocktails and drinks.</p>"
          ></ImgCard>


        </div>
        <div class="col-sm-12 col-md-6">
          <ImgCard title="Bagatelle Los Cabos"
                   link="https://www.cabo.party/los-cabos-clubs/bagatelle-los-cabos"
                   action-label="DETAILS"
                   sub-title="You will find Bagatelle Los Cabos located in Medano Beach"
                   image="https://imgix.cosmicjs.com/38c29680-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos.jpg"
                   text="<p>From 11:00 am till late  <a href='https://www.cabo.party/los-cabos-clubs/bagatelle-los-cabos'>Bagatelle Cabo</a> is a <b>French Mediterranean restaurant and festive club</b> by the beach with the perfect blend of a elegant dinner + party. <br><br> Are you looking for a dinner show with a lively atmosphere and natural scenarios? , <a href='https://www.cabo.party/los-cabos-clubs/bagatelle-los-cabos'>Bagatelle Los Cabos</a> is the option!</b>"
          ></ImgCard>


        </div>
        <div class="col-sm-12 col-md-6">
          <ImgCard title="Crania Los Cabos"
                   link="https://www.cabo.party/los-cabos-clubs/crania-los-cabos"
                   action-label="DETAILS"
                   sub-title="The hottest kid in town."
                   image="https://imgix.cosmicjs.com/af05d360-f76b-11ee-b555-0d0678c27dd7-crania-los-cabos-photo.jpg"
                   text="<p><a href='https://www.cabo.party/los-cabos-clubs/crania-los-cabos'>Crania Los Cabos</a> is a <strong>Restaurant, Cocktail Bar & Air Disco</strong> that ensures a reset of spaces and structures through fun moments.
From <strong>Wednesday to Saturday</strong> Crania hosts dinner nights, social events, disco nights & even all-day festivals in its outdoor space. It gives a new perspective marked with its re-purposed, once-abandoned, cranes.</p>"
          ></ImgCard>
        </div>
        <div class="col-sm-12 col-md-6">
          <ImgCard title="Rosa Negra Los Cabos"
                   link="https://www.cabo.party/los-cabos-clubs/rosa-negra-los-cabos"
                   action-label="DETAILS"
                   sub-title="The moments that make life sparkle with joy and celebration."
                   image="https://imgix.cosmicjs.com/80a40840-01a5-11ef-bec5-7f9403a32f29-rosa-negra-los-cabos.jpg?h=230&q=&fit=clamp&w=386&format=auto"
                   text="<p>Unforgettable evenings begin at <a href='https://www.cabo.party/los-cabos-clubs/rosa-negra-los-cabos'>Rosa Negra Los Cabos</a>. Savor high-quality dishes bursting with Latin American flavor, all set to a <strong>vibrant atmosphere</strong> that's unlike anything else.
Gather your friends and experience a <strong>group dinner</strong> you won't forget.</p>"
          ></ImgCard>
        </div>


      </div>

    </div>
  </div>
</template>
<script>

import {TimeLine, TimeLineItem} from 'src/components';
import MiniCaboEventCard from '../../components/EventCard/MiniCaboEventCard';
import {CABO_BASE_URL} from '@/common/request';
import moment from "moment";
import ClubCard from "@/components/Cards/ClubCard";
import ImgCard from "@/components/Cards/ImgCard";

export default {
  name: 'Cabo',
  created: function () {
    setTimeout(() => (this.showPalms = true), 5000);
    this.$store.dispatch('getAllCaboParties');
  },
  props: ['slug'],
  metaInfo() {
    return {
      title: 'Top Clubs, Events & Parties in Cabo San Lucas',
      meta: [
        {
          name: 'description',
          content: `Tickets & Information for the best events in Cabo San Lucas`,
        },
        {
          name: 'keywords',
          content: `San Jose Cabo, Parties, Cabo San Lucas, Events`,
        },
        {
          property: 'og:title',
          content: `Top Events & Parties in Cabo San Lucas`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/san-jose-cabo-party`,
        },
        {
          property: 'og:description',
          content: `Event Tickets & Club Reservations @ Cabo San Lucas Mexico`,
        },
        {property: 'og:type', content: 'article'},
        // {
        //   property: 'og:image',
        //   content: this.festival.metadata.logo && this.festival.metadata.logo.imgix_url
        // }
      ],
    };
  },
  data: () => {
    return {
      showPalms: false,
      djImage: 'https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg',
      contactNumber: '524421049872',
      cardData: {metadata: {image: {}, type: {}}},
      caboImg: 'https://imgix.cosmicjs.com/1eb5e990-f3e6-11ee-b555-0d0678c27dd7-Bits-of-color.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=3EA2C1,black&duotone-alpha=100&auto=format',

    };
  },
  components: {
    ImgCard,
    TimeLine,
    ClubCard,
    TimeLineItem,
    MiniCaboEventCard,
  },
  methods: {
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    onQuickReserveClick() {
      window.ga('send', 'event', 'reservations', 'Cabo info request', 'Main', 20);
      this.$rollbar.info('Cabo Info request');
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },


  mounted() {
    // fetch(
    //   encodeURI(
    //     `${CABO_BASE_URL}&props=title,slug,content,metadata&query={"type":"cabo-dynamic-card","slug": "cabo-dynamic-card"}`
    //   )
    // ).then((response) => {
    //   response.json().then((res) => {
    //     this.cardData = res.objects[0];
    //     this.cardData.title = this.title;
    //   })}
    // );
  },
  computed: {
    caboParties: {
      get() {
        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');

          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...this.$store.state.cabo_calendar]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
  },
};
</script>
